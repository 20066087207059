/**
 * Created by shnell on 16.04.15.
 */

/**
 * Created by shnell on 22.05.15.
 */
(function ($) {
    $.fn.simpleSlider = function (options) {

        var settings = $.extend({
                stepSize: 200,
                animationSpeed: 500,
                vertical: false,
                cycled: false,
                navNext: '.next',
                navPrev: '.prev',
                list: '.slider-items',
                items: '> li'
            }, options),
            slideTrigger = false;

        var $list = $(settings.list),
            itemsCount = $list.find(settings.items).size(),
            $itemsClone = $list.find(settings.items).clone(),
            propNav = (settings.vertical) ? 'top' : 'left',
            curPos = Math.round(itemsCount / 2),
            afterNext = function () {
                slideTrigger = false;
                curPos--;

                if (settings.cycled && (curPos - itemsCount <= 0)) {
                    curPos += $itemsClone.size();

                    var diffWidth = (settings.stepSize * $itemsClone.size());

                    $list.css('width', '+=' + diffWidth + 'px');
                    $itemsClone.clone().prependTo($list);
                    $list.css(propNav, '-=' + diffWidth + 'px');
                }
            },
            afterPrev = function () {
                slideTrigger = false;
                curPos++;

                if (settings.cycled && (Math.abs($list.find(settings.items).size() - curPos) <= itemsCount*2)) {
                    var diffWidth = (settings.stepSize * $itemsClone.size());

                    $list.css('width', '+=' + diffWidth + 'px');
                    $itemsClone.clone().appendTo($list);
                }
            },
            next = function() {
                if (!slideTrigger && (settings.cycled || parseInt($list.css(propNav)) < 0)) {
                    slideTrigger = true;

                    var animateOpts = {};
                    animateOpts[propNav] = "+=" + settings.stepSize;

                    $list.animate(animateOpts, settings.animationSpeed, afterNext);
                }
            },
            prev = function() {
                if (!slideTrigger && (settings.cycled || (parseInt($list.css(propNav)) > -((itemsCount - 1) * settings.stepSize) || $list.css(propNav) == 'auto'))) {
                    slideTrigger = true;

                    var animateOpts = {};
                    animateOpts[propNav] = "-=" + settings.stepSize;

                    $list.animate(animateOpts, settings.animationSpeed, afterPrev);
                }
            };

        $(settings.navNext).on('click', next);
        $(settings.navPrev).on('click', prev);

        if (settings.cycled) {
            $list.find(settings.items).clone().prependTo($list).end().clone().appendTo($list);
            $list.css(propNav, '-' + (itemsCount * settings.stepSize) + 'px');
        }

        if (settings.vertical) {
            $list.height($list.find(settings.items).size() * settings.stepSize + 100);
        } else {
            $list.width($list.find(settings.items).size() * settings.stepSize + 100);
        }
    };
})(jQuery);
/**
 * Created by shnell on 07.08.15.
 */

function number_format (number, decimals, dec_point, thousands_sep) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }

    return s.join(dec);
}

$(function () {
    $('input, select').styler();

    // categories menu
    $('.categories-navbar.simple-slider').simpleSlider({
        stepSize: 270,
        cycled: true,
        navPrev: '.categories-navbar .right-arrow',
        navNext: '.categories-navbar .left-arrow',
        list: '.categories-navbar .slider-items',
        items: '> li'
    });

    $('.logos-line.simple-slider').simpleSlider({
        stepSize: 185,
        cycled: true,
        navPrev: '.logos-line .right-arrow',
        navNext: '.logos-line .left-arrow',
        list: '.logos-line .slider-items',
        items: '> li'
    });

    $('.news, .main-block').on('click', '.news-elem', function() {
        window.location = $(this).find('.title a').attr("href");
    });

    $('.toggle-form-btn').on('click', function() {
        var formSelector = $(this).data('form');
        if (!!formSelector) {
            $(formSelector).slideToggle();
        }
    });

});
/**
 * Created by shnell on 16.04.15.
 */
$(function () {

    $('.news .slider-items').simpleSlider({
        stepSize: 242,
        vertical: true,
        navNext: '.news-voting .navigation-up',
        navPrev: '.news-voting .navigation-down',
        list: '.news .slider-items',
        items: '> div'
    });


    //quick menu and categories navbar
    var topPosition = 400;
    var bottomPosition = $('section.footer').height();
    var windowHeight = $( window ).height();

    $(document).scroll(function () {
        var pageHeight = $(document).height();
        var scrollTop = $(this).scrollTop();

        if (scrollTop - topPosition > 0) {
            $('.right-quick-menu').addClass('dark');
        } else {
            $('.right-quick-menu').removeClass('dark');
        }

        if (scrollTop - topPosition - 130 > 0) {
            $('.categories-navbar-dummy').show();
            $('.categories-navbar').css('position', 'fixed');
        } else {
            $('.categories-navbar-dummy').hide();
            $('.categories-navbar').css('position', '');
        }

        if (scrollTop - topPosition - 130 > 0 && pageHeight - windowHeight - scrollTop > bottomPosition) {
            $('.contacts-panel').show();
        } else {
            $('.contacts-panel').hide();
        }
    });

    // comments slider
    $('.comments-slider').bxSlider({
        auto: true,
        controls: false,
        pause: 8000
    });
});